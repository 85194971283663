import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import loginButtonSrc from '../../assets/image/loginButton.png'
import logoSrc from '../../assets/image/logo.png'
import { UserType } from '../../atom/user'
import { SERVER_URL } from '../../config'
import decodeUTF8String from '../../helpers/decodeUTF8String'
import useUser from '../../hooks/useUser'
import { setAccessToken } from '../../service/privateFetch'

const { Kakao } = window

type AuthorizeQueryType = {
  status: 'success' | 'error'
  access_token?: string
}

const Login = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [isShowError, setIsShowError] = useState(false)
  const [_, setUser] = useUser()
  useEffect(() => {
    const query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    }) as AuthorizeQueryType
    console.log(query)
    switch (query?.status) {
      case 'success':
        const { access_token } = query
        setAccessToken(access_token)
        const user = JSON.parse(atob(access_token.split('.')[1])) as UserType
        user.nickname = decodeUTF8String(user.nickname)
        setUser(user)
        navigate('/home')
        break
      case 'error':
        setIsShowError(true)
        break
      default:
        break
    }
  }, [location])
  const onClickLoginButton = () => {
    Kakao.Auth.authorize({
      redirectUri: `${SERVER_URL}/api/auth`,
    })
  }
  return (
    <Container>
      <Logo src={logoSrc} />
      <LoginButtonWrapper>
        <LoginButton src={loginButtonSrc} onClick={onClickLoginButton} />
        {isShowError && <ErrorText>로그인이 실패 했습니다. 다시 시도해주시기 바랍니다.</ErrorText>}
      </LoginButtonWrapper>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const Logo = styled.img`
  width: 50%;
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
`
const LoginButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
`
const LoginButton = styled.img`
  width: 100%;
`
const ErrorText = styled.div`
  width: 100%;
  color: red;
`

export default Login
