import { Empty, List, SwipeAction } from 'antd-mobile'
import React from 'react'
import { styled } from 'styled-components'
import knockState, { KnockType } from '../../atom/knock'
import { Action } from 'antd-mobile/es/components/swipe-action'
import { CheckCircleOutline, CloseCircleOutline, DeleteOutline } from 'antd-mobile-icons'
import { useSetRecoilState } from 'recoil'
import { acceptKnock, declineKnock, deleteKnock } from '../../service/knock'

const KnockList = ({ knockList }: { knockList: KnockType[] }) => {
  const setKnockList = useSetRecoilState(knockState)
  const deleteAction: (knockId: string) => Action = (knockId: string) => ({
    key: 'delete',
    text: <DeleteOutline />,
    onClick: async () => {
      try {
        const { knockList: _knockList } = await deleteKnock({ knockId })
        setKnockList(_knockList)
      } catch (e) {
        console.log(e)
      }
    },
    color: 'black',
  })
  const acceptAction: (knockId: string) => Action = (knockId: string) => ({
    key: 'accept',
    text: <CheckCircleOutline />,
    onClick: async () => {
      try {
        const { knockList: _knockList } = await acceptKnock({ knockId })
        setKnockList(_knockList)
      } catch (e) {
        console.log(e)
      }
    },
    color: 'primary',
  })
  const declineAction: (knockId: string) => Action = (knockId: string) => ({
    key: 'decline',
    text: <CloseCircleOutline />,
    onClick: async () => {
      try {
        const { knockList: _knockList } = await declineKnock({ knockId })
        setKnockList(_knockList)
      } catch (e) {
        console.log(e)
      }
    },
    color: 'danger',
  })
  return (
    <StyledList>
      {knockList.length === 0 ? (
        <Empty />
      ) : (
        <>
          {knockList.map(({ knockAt, knockId, state, isReceived }, i) => {
            const rightActions: Action[] = []
            if (state === 'pending') {
              if (isReceived) {
                rightActions.push(acceptAction(knockId))
                rightActions.push(declineAction(knockId))
              } else {
                rightActions.push(deleteAction(knockId))
              }
            } else if (state === 'declined') {
              rightActions.push(deleteAction(knockId))
            }
            return (
              <SwipeAction key={i} rightActions={rightActions}>
                <Item>
                  <span>신청일자: {knockAt}</span>
                  <span>상태: {state}</span>
                </Item>
              </SwipeAction>
            )
          })}
        </>
      )}
    </StyledList>
  )
}
const StyledList = styled(List)``
const Item = styled(List.Item)`
  width: 100%;
  box-sizing: border-box;
  padding: 0 12px;
  > div {
    width: 100%;
    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
`

export default KnockList
