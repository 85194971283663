import PropTypes from 'prop-types'
import React from 'react'
import { Navigate } from 'react-router-dom'
import { getAccessToken } from '../service/privateFetch'

const ProtectedRoute = ({ children }) => {
  const token = getAccessToken()
  if (!token) {
    return <Navigate to={'/login'} replace />
  }

  return children
}
ProtectedRoute.propTypes = {
  children: PropTypes.node.isRequired,
}
export default ProtectedRoute
