import { Button, NavBar } from 'antd-mobile'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import logout from '../helpers/logout'
import useUser from '../hooks/useUser'

const Nav = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [user] = useUser()
  return (
    <StyledNavBar
      backArrow={location.pathname === '/knock/create'}
      onBack={() => navigate(-1)}
      right={<Button onClick={() => logout()}>logout</Button>}
    >
      {user && user.nickname}
    </StyledNavBar>
  )
}
const StyledNavBar = styled(NavBar)`
  width: 100%;
  height: 51px;
  box-sizing: border-box;
`
export default Nav
