import { atom, selector } from 'recoil'
export type KnockState = 'pending' | 'accepted' | 'declined'
export type KnockType = {
  knockId: string

  isReceived: boolean

  isSuperKnock: boolean

  state: KnockState

  knockAt: string

  createdAt: string
}
export const knockState = atom<KnockType[]>({
  key: 'knockState',
  default: [],
})
export const isKnockInitializedState = atom<boolean>({
  key: 'isKnockInitializedState',
  default: false,
})
export const knockMapState = selector<Map<string, KnockType[]>>({
  key: 'knockMapState',
  get: ({ get }) => {
    const knockMap = new Map<string, KnockType[]>()
    const knockList = get(knockState)
    knockList.forEach((knock) => {
      const key = knock.knockAt
      const KnockInSameDateList = knockMap.has(key) ? knockMap.get(key) : []
      KnockInSameDateList.push(knock)
      knockMap.set(key, KnockInSameDateList)
    })
    return knockMap
  },
})
export default knockState
