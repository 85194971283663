import React, { useEffect } from 'react'
import { SERVER_URL } from '../config'
import { useRecoilValue } from 'recoil'
import userState from '../atom/user'
import { getGlobalRegistration } from '../serviceWorkerRegistration'
export interface ISseMessage {
  event: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

const Sse = () => {
  const user = useRecoilValue(userState)
  useEffect(() => {
    if (user) {
      const eventSource = new EventSource(`${SERVER_URL}/sse/${user.userId}`)
      eventSource.onmessage = ({ data }) => {
        const message: ISseMessage = JSON.parse(data)
        if (message.event === 'newKnock') {
          const globalRegistration = getGlobalRegistration()
          globalRegistration.showNotification('new knock!')
        }
        console.log(message)
      }
    }
  }, [user])
  return <></>
}
export default Sse
