import React from 'react'
import { useLocation } from 'react-router-dom'
import { styled } from 'styled-components'
import Nav from '../../components/Nav'
import TabBars from '../../components/TabBars'
import useUser from '../../hooks/useUser'
import CreateKnock from '../Knock/CreateKnock'
import Knock from '../Knock/Knock'
import Calendar from './Calendar'
import Matching from './Matching'

const Home = () => {
  const [user] = useUser()
  const location = useLocation()
  if (!!user?.mateUserId === false) {
    return <Matching />
  }
  return (
    <>
      <Nav />
      <Content>
        {location?.pathname === '/home' && <Calendar />}
        {location?.pathname === '/knock' && <Knock />}
        {location?.pathname === '/knock/create' && <CreateKnock />}
      </Content>
      <TabBars />
    </>
  )
}
const Content = styled.div`
  width: 100%;
  height: calc(100% - 102px);
`
export default Home
