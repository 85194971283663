import { PasscodeInput, PasscodeInputRef } from 'antd-mobile'
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import useUser from '../../hooks/useUser'
import { setMatching } from '../../service/user'

const Matching = () => {
  const [user, setUser] = useUser()
  const ref = useRef<PasscodeInputRef>()
  const navigate = useNavigate()
  const [mateNumber, setMateNumber] = useState<number>(null)
  const [errorMessage, setErrorMessage] = useState<string>(null)
  return (
    <Container>
      <MateNumberWrapper>
        짝궁의 고유번호를 입력해주세요.
        <PasscodeInput
          length={5}
          value={mateNumber?.toString()}
          plain
          ref={ref}
          error={!!errorMessage}
          onFill={async () => {
            ref?.current?.blur()
            try {
              const { user: newUser } = await setMatching(mateNumber)
              setUser(newUser)
              navigate('/home')
            } catch (e) {
              const response = e as Response
              if (response?.status === 404) {
                setErrorMessage('존재하지 않는 유저입니다.')
              } else if (response?.status === 400) {
                setErrorMessage('올바르지 않은 고유번호 입니다.')
              }
            }
          }}
          onChange={(value) => {
            const number = parseInt(value, 10)
            if (!Number.isNaN(number)) {
              setMateNumber(number)
            } else if (value.length === 0) {
              setMateNumber(null)
            }
            setErrorMessage(null)
          }}
        />
        {errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </MateNumberWrapper>
      <MyNumberWrapper>
        내 고유 번호 <br />
        {user?.matchingNumber}
      </MyNumberWrapper>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
const MateNumberWrapper = styled.div`
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
`
const ErrorText = styled.div`
  color: red;
`
const MyNumberWrapper = styled.div`
  position: absolute;
  bottom: 20%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
`
export default Matching
