import { useRecoilState } from 'recoil'
import userState, { UserType } from '../atom/user'

type SetUserType = (user: UserType) => void
const useUser = (): [UserType, SetUserType] => {
  const [user, setUser] = useRecoilState(userState)
  const customSetUser = (newUser: UserType) => {
    localStorage.setItem('user', JSON.stringify(newUser))
    setUser(newUser)
  }
  console.log(user)
  return [user, customSetUser]
}
export default useUser
