import logout from '../helpers/logout'

interface IAuthHeader {
  Authorization?: string
}
const generateAuthHeader = (accessToken: string): IAuthHeader => {
  if (accessToken) {
    return {
      Authorization: `Bearer ${accessToken}`,
    }
  }
  return {}
}
export const getAccessToken = () => localStorage.getItem('token')
export const setAccessToken = (accessToken: string) => {
  localStorage.setItem('token', accessToken)
}

// eslint-disable-next-line no-undef
const privateFetch = async (url: string, options: RequestInit, accessToken: string = null) => {
  let authHeader: IAuthHeader = null
  if (accessToken) {
    authHeader = generateAuthHeader(accessToken)
  } else {
    authHeader = generateAuthHeader(getAccessToken())
  }
  const response = await fetch(url, {
    ...options,
    headers: { ...options.headers, ...authHeader },
  })
  if (response.ok) {
    return response
  }
  if (response.status === 401) {
    const { message } = await response.json()
    if (message === 'tokenExpired') {
      alert('세션이 만료되었습니다. 다시 로그인해 주세요.')
      logout()
    }
  }
  throw response
}
export default privateFetch
