import { Button, Tabs } from 'antd-mobile'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { styled } from 'styled-components'
import { KnockType } from '../../atom/knock'
import useKnockList from '../../hooks/useKnockList'
import KnockList from './KnockList'

const Knock = () => {
  const navigate = useNavigate()
  const knockList = useKnockList()
  const knockListObject = useMemo<{
    acceptedKnockList: KnockType[]
    receivedKnockList: KnockType[]
    sentKnockList: KnockType[]
  }>(() => {
    const acceptedKnockList = []
    const receivedKnockList = []
    const sentKnockList = []
    knockList.forEach((knock) => {
      if (knock.state === 'accepted') {
        acceptedKnockList.push(knock)
      } else if (knock.isReceived) {
        receivedKnockList.push(knock)
      } else {
        sentKnockList.push(knock)
      }
    })
    return {
      acceptedKnockList,
      receivedKnockList,
      sentKnockList,
    }
  }, [knockList])
  return (
    <Container>
      <StyledTabs>
        <Tabs.Tab title="노크" key="knock">
          <KnockList knockList={knockListObject.acceptedKnockList}></KnockList>
        </Tabs.Tab>
        <Tabs.Tab title="받은 요청" key="receive">
          <KnockList knockList={knockListObject.receivedKnockList}></KnockList>
        </Tabs.Tab>
        <Tabs.Tab title="보낸 요청" key="sent">
          <KnockList knockList={knockListObject.sentKnockList}></KnockList>
        </Tabs.Tab>
      </StyledTabs>
      <CreateButtonWrapper>
        <CreateButton block color="primary" onClick={() => navigate('/knock/create')}>
          노크 생성
        </CreateButton>
      </CreateButtonWrapper>
    </Container>
  )
}
const Container = styled.div`
  height: calc(100%);
`
const StyledTabs = styled(Tabs)`
  height: calc(100% - 89.2px);
  > div.adm-tabs-header {
    height: 40px;
  }
  > div.adm-tabs-content:nth-child(n + 1) {
    height: calc(100% - 40px);
    overflow: scroll;
    box-sizing: border-box;
    border: 1px solid black;
  }
  > div.adm-tabs-content:last-child {
    height: calc(100% - 40px);
    overflow: scroll;
    box-sizing: border-box;
    border: 1px solid black;
  }
`
const CreateButtonWrapper = styled.div`
  width: 100%;
  height: 89.2px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 12px;
  box-sizing: border-box;
`
const CreateButton = styled(Button)`
  width: 100%;
  height: 100%;
`
export default Knock
