import React, { useState } from 'react'
import AntdCalendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css'
import { useRecoilValue } from 'recoil'
import { styled } from 'styled-components'
import { knockMapState } from '../../atom/knock'
import getDateString from '../../helpers/getDateString'
import { Button } from 'antd-mobile'
import { useNavigate } from 'react-router-dom'
import useKnockList from '../../hooks/useKnockList'

const Calendar = () => {
  const [date, setDate] = useState<Date>(new Date())
  const [activeStartDate, setActiveStartDate] = useState<Date>(null)
  const knockMap = useRecoilValue(knockMapState)
  const navigate = useNavigate()
  useKnockList()
  return (
    <Container>
      <StyledCalendar
        onChange={(e) => {
          setDate(e as Date)
        }}
        value={date}
        view="month"
        onActiveStartDateChange={(e) => {
          if (e.action === 'drillUp') {
            const today = new Date()
            setDate(today)
            setActiveStartDate(today)
          } else {
            setActiveStartDate(null)
          }
        }}
        activeStartDate={activeStartDate}
        tileContent={(e) => {
          const key = getDateString(e.date)
          if (knockMap.has(key)) {
            const knockList = knockMap.get(key)
            if (knockList.some(({ state }) => state === 'accepted')) {
              return <div>&#x2022;</div>
            }
          }
          return <div>&nbsp;</div>
        }}
      />
      <StyledButton
        onClick={() => {
          window.Notification.requestPermission()
          navigate(`knock/create?date=${getDateString(date)}`)
        }}
      >
        {' '}
        노크하기 +{' '}
      </StyledButton>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const StyledCalendar = styled(AntdCalendar)``
const StyledButton = styled(Button)`
  margin-top: 20px;
`
export default Calendar
