import urlJoin from 'url-join'
import { KnockType } from '../atom/knock'
import { UserType } from '../atom/user'
import { SERVER_URL } from '../config'
import privateFetch from './privateFetch'

export const getKnock = async (): Promise<{
  knockList: KnockType[]
}> => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  return privateFetch(urlJoin(SERVER_URL, '/api/knock'), requestOptions).then((res) => res.json())
}

export const createKnock = async ({
  knockAt,
  isSuperKnock,
}: {
  knockAt: string
  isSuperKnock: boolean
}): Promise<{
  user: UserType
  knockList: KnockType[]
}> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ knockAt, isSuperKnock }),
  }
  return privateFetch(urlJoin(SERVER_URL, '/api/knock'), requestOptions).then((res) => res.json())
}

export const acceptKnock = async ({
  knockId,
}: {
  knockId: string
}): Promise<{
  knockList: KnockType[]
}> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ state: 'accepted' }),
  }
  return privateFetch(urlJoin(SERVER_URL, `/api/knock/${knockId}`), requestOptions).then((res) =>
    res.json()
  )
}

export const declineKnock = async ({
  knockId,
}: {
  knockId: string
}): Promise<{
  knockList: KnockType[]
}> => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ state: 'declined' }),
  }
  return privateFetch(urlJoin(SERVER_URL, `/api/knock/${knockId}`), requestOptions).then((res) =>
    res.json()
  )
}

export const deleteKnock = async ({
  knockId,
}: {
  knockId: string
}): Promise<{
  knockList: KnockType[]
}> => {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
  }
  return privateFetch(urlJoin(SERVER_URL, `/api/knock/${knockId}`), requestOptions).then((res) =>
    res.json()
  )
}
