import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { TabBar } from 'antd-mobile'
import { CalendarOutline, FileOutline, FireFill } from 'antd-mobile-icons'
import { styled } from 'styled-components'

const TabBars = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { pathname } = location

  const tabs = [
    {
      key: '/home',
      title: 'Home',
      icon: <CalendarOutline />,
    },
    {
      key: '/knock',
      title: 'Knock',
      icon: <FileOutline />,
    },
    {
      key: '/super',
      title: 'Super Power',
      icon: <FireFill />,
    },
  ]

  return (
    <Container>
      <TabBar activeKey={pathname} onChange={(value) => navigate(value)}>
        {tabs.map((item) => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
        ))}
      </TabBar>
    </Container>
  )
}
const Container = styled.div`
  width: 100%;
  height: 51px;
  position: absolute;
  bottom: 0;
  left: 0;
  div.adm-tab-bar-item {
    border: 1px solid red;
  }
`

export default TabBars
