import { Button, DatePicker, DatePickerRef, Form, Switch } from 'antd-mobile'
import dayjs from 'dayjs'
import React, { RefObject, useMemo, useRef } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { styled } from 'styled-components'
import knockState from '../../atom/knock'
import getDateString from '../../helpers/getDateString'
import useUser from '../../hooks/useUser'
import { createKnock } from '../../service/knock'
export type CreateKnockType = {
  knockAtDate: Date
  isSuperKnock: boolean
}
const CreateKnock = () => {
  const errorMessageRef = useRef<string>(null)
  const [form] = Form.useForm<CreateKnockType>()
  const setKnockList = useSetRecoilState(knockState)
  const [user, setUser] = useUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const initialDate = useMemo(() => {
    if (searchParams.has('date')) {
      return new Date(searchParams.get('date'))
    } else {
      return null
    }
  }, [searchParams])
  const onFinish = async ({ knockAtDate, isSuperKnock }: CreateKnockType) => {
    try {
      if (!errorMessageRef.current) {
        const { user: _user, knockList } = await createKnock({
          knockAt: getDateString(knockAtDate),
          isSuperKnock,
        })
        setKnockList(knockList)
        setUser(_user)
        navigate(-1)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      const { message } = await e.json()
      if (message === 'Knock already exist.') {
        errorMessageRef.current = '해당 날짜에 이미 노크가 존재합니다.'
        form.submit()
      }
    }
  }
  const hasSuperPower = useMemo(() => {
    return user?.superPower > 0
  }, [user?.superPower])
  return (
    <>
      <StyledForm
        form={form}
        layout="horizontal"
        footer={
          <Button block type="submit" color="primary" size="large">
            요청하기
          </Button>
        }
        onFinish={onFinish}
        initialValues={{
          knockAtDate: initialDate,
          isSuperKnock: false,
        }}
      >
        <Form.Item
          name="knockAtDate"
          label="신청날짜"
          trigger="onConfirm"
          onClick={(_, datePickerRef: RefObject<DatePickerRef>) => {
            datePickerRef.current?.open()
          }}
          rules={[
            { required: true, message: '날짜를 입력해주세요.' },
            {
              validator() {
                const errorMessage = errorMessageRef.current
                if (errorMessage) {
                  return Promise.reject(new Error(errorMessage))
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <DatePicker
            confirmText="확인"
            cancelText="취소"
            onSelect={() => (errorMessageRef.current = null)}
          >
            {(value) => (value ? dayjs(value).format('YYYY-MM-DD') : '신청날짜')}
          </DatePicker>
        </Form.Item>
        <Form.Item
          name="isSuperKnock"
          label={`슈퍼노크 (${hasSuperPower ? user?.superPower : '없음'})`}
          childElementPosition="right"
        >
          <Switch disabled={!hasSuperPower} />
        </Form.Item>
      </StyledForm>
    </>
  )
}
const StyledForm = styled(Form)`
  height: calc(100%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export default CreateKnock
