import { useRecoilState } from 'recoil'
import knockState, { KnockType, isKnockInitializedState } from '../atom/knock'
import { useEffect } from 'react'
import { getKnock } from '../service/knock'

const useKnockList = (): KnockType[] => {
  const [knockList, setKnockList] = useRecoilState(knockState)
  const [isKnockInitialized, setIsKnockInitialized] = useRecoilState(isKnockInitializedState)
  useEffect(() => {
    const f = async () => {
      if (!isKnockInitialized) {
        const { knockList: _knockList } = await getKnock()
        setKnockList(_knockList)
        setIsKnockInitialized(true)
      }
    }
    f()
  }, [isKnockInitialized])
  console.log(knockList)
  return knockList
}

export default useKnockList
