import { atom } from 'recoil'

export type UserType = {
  userId: string
  userEmail: string
  nickname: string
  mateUserId: string
  matchingNumber: number
  superPower: number
}
let defaultUser = null
const userJson = localStorage.getItem('user')
if (userJson) {
  defaultUser = JSON.parse(userJson)
}
export const userState = atom<UserType>({
  key: 'userState',
  default: defaultUser,
})
export default userState
