import urlJoin from 'url-join'
import { SERVER_URL } from '../config'
import privateFetch from './privateFetch'
import { UserType } from '../atom/user'

export const getUser = async (): Promise<{
  user: UserType
}> => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' },
  }
  return privateFetch(urlJoin(SERVER_URL, '/api/user'), requestOptions).then((res) => res.json())
}
export const setMatching = async (
  matchingNumber: number
): Promise<{
  user: UserType
}> => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ matchingNumber }),
  }

  return privateFetch(urlJoin(SERVER_URL, '/api/user/matching'), requestOptions).then((res) =>
    res.json()
  )
}
